import React, { useEffect, useState } from 'react'
import Navbar from '../component/navbar'
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useParams, useSearchParams } from 'react-router-dom';
import "./css/meeting.css"
import { Alert, AutoComplete, Image, message } from 'antd';
import ReactPlayer from 'react-player';
import axios from 'axios'

function Meeting() {

    const { id } = useParams();
    const [meeting, setmeeting] = useState([])
    const [user, setuser] = useState([])
    const [entermeeting, setentermeeting] = useState(false)
    const [chatbox, setchatbox] = useState(false)
    const [date, setdate] = useState([])
    const [registerform, setregisterform] = useState(true)
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [open, setOpen] = useState(false);
    const [content, setcontent] = useState("");
    const [messagesss, setmessagesss] = useState([])
    const [inputquestin, setinputquestin] = useState("")

    const [countries, setCountries] = useState([]);
    const [searchParams] = useSearchParams();
    const namee = searchParams.get('name');
    const emaill = searchParams.get('email');
    const login = searchParams.get('login');
    const [options, setOptions] = useState([{
        value: "United Kingdom"
    }, {
        value: "Ireland"
    }]);

    useEffect(() => {



        if (login === "true") {
            setname(namee)
            setemail(emaill)


            // let user
            // if (meeting.length > 0) {

            //     const user = meeting.user.find(user =>
            //         user.Email.toLowerCase() === emaill.toLowerCase()
            //     );
            // }
            // if (user) {
            //     // alert("login")
            //     setentermeeting(true)
            // } else {
                // alert("nhi")
                setentermeeting(true)
            // }


        }


        const date = new Date(meeting.Schedule);

        const day = date.getDate();          // 09
        const month = date.getMonth() + 1;   // 09 (Note: months are zero-indexed, so add 1)
        const year = date.getFullYear();     // 2024
        let hours = date.getHours();         // 13
        const minutes = date.getMinutes();

        const monthNames = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];

        // Determine AM/PM
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        hours = hours % 12 || 12; // If hour is 0 (midnight), convert to 12

        // Format minutes to always have two digits
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        setdate({
            day: day,
            month: monthNames[month - 1],    // Get the month name from the array
            year: year,                      // 2024
            hours: hours,                    // Converted to 12-hour format
            minutes: formattedMinutes,       // Format minutes to 2 digits
            period: period                   // AM or PM
        });

    }, [meeting])





    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                // Sort countries alphabetically by name
                const sortedCountries = response.data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                );
                setCountries(sortedCountries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);






    useEffect(() => {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("URL", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getalleventbyurl", requestOptions)
            .then(response => response.json())
            .then(result => {
                setmeeting(result)
                setmessagesss(result.questions)

                if (result.lessthen10) {
                    setregisterform(false)
                }
            })
            .catch(error => console.log('error', error));



    }, [])

    const myMeeting = async (element) => {


        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
            parseInt("1426619796"),
            "224cefed6979db88285a5c524756adf4",
            id,//meeting id
            email, //for test
            name,//for test
            // user?.uid ? user.uid : generateMeetingID(),
            // user?.displayName ? user.displayName : generateMeetingID()
        );
        const zp = ZegoUIKitPrebuilt.create(kitToken);

        zp?.joinRoom({
            container: element,
            showPreJoinView: false,
            // showScreenSharingButton: true,
            // showRemoveUserButton: true,
            // showRoomTimer: true,

            sharedLinks: [
                {
                    name: meeting.title,
                    url: "academy.yourgutmap.co.uk/" + id,
                },
            ],
            scenario: {
                mode: "LiveStreaming",
                config: {
                    role: "Audience",
                }
            },

            onJoinRoom: () => {




                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("_id", meeting._id);
                urlencoded.append("email", email);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addjoingintime", requestOptions)
                    .then((response) => response.text())
                    .then((result) => console.log(result))
                    .catch((error) => console.error(error));


            },
        });



    };






    const CountdownTimer = () => {
        const dateString = meeting.Schedule;
        const targetDate = new Date(dateString).getTime();  // Convert the date string to a timestamp


        const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

        useEffect(() => {
            const timer = setInterval(() => {
                setTimeRemaining(calculateTimeRemaining());
            }, 1000);  // Update every second

            return () => clearInterval(timer);  // Clean up interval on component unmount
        }, []);

        function calculateTimeRemaining() {
            // const now = new Date().getTime();  // Current time in milliseconds
            const now = new Date(); // Get the current date and time
            const utcOffset = now.getTimezoneOffset() * 60000; // Get the timezone offset in milliseconds
            const londonOffset = 0; // London is UTC+1 during BST and UTC+0 during GMT
            const ukNow = new Date(now.getTime() + utcOffset + londonOffset).getTime(); // Adjust to UK time

            const difference = targetDate - ukNow;  // Time difference in milliseconds

            if (difference <= 0) {
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };  // If the time is up, return zero values
            }

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            return { days, hours, minutes, seconds };
        }

        return (
            <div className="countdown-container">
                <h1 className="countdown-title">Remaining time to <span className="highlight">start the event</span></h1>
                <div className="countdown">
                    <div className="countdown-item">
                        <div className="countdown-number">{timeRemaining.days}</div>
                        <div className="countdown-label">DAYS</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-number">{timeRemaining.hours}</div>
                        <div className="countdown-label">HOURS</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-number">{timeRemaining.minutes}</div>
                        <div className="countdown-label">MINUTES</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-number">{timeRemaining.seconds}</div>
                        <div className="countdown-label">SECONDS</div>
                    </div>
                </div>
            </div>
        );
    };




    const [formData, setFormData] = useState(() => {
        const initialData = {};
        const fields = meeting?.Fields || [];
        fields.forEach(field => {
            initialData[field.title] = field.type === 'checkbox' ? false : '';
        });
        return initialData;
    });

    const handleChange = (title, value) => {
        setFormData(prevData => ({
            ...prevData,
            [title]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        const hide = message.loading("Action in progress", 0)


        if (meeting.user.find(value => value.Email === formData.Email)) {
            console.log(formData.Email)

                setcontent("This Email is Already Registerd")
                setOpen(true)
                setTimeout(() => {
                    hide(); // Call hide to stop the loading message

                }, 2000);


                setregisterform(false)
        }else{




        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("user", JSON.stringify(formData));
        urlencoded.append("_id", meeting._id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/adduserinevent", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setmeeting(result)

                setcontent("Thank you for registering! Please check your email for further details")
                setOpen(true)
                setTimeout(() => {
                    hide(); // Call hide to stop the loading message

                }, 2000);


                setregisterform(false)


            })
            .catch((error) => console.error(error));




    }




        // or send this data to the server
    };

    if (!meeting || !meeting.Fields) {
        // Handle the case where meeting or meeting.Fields is undefined
        return <div>Loading...</div>;
    }


    const checkuser = async (e) => {
        e.preventDefault()

        const user = meeting.user.find(user =>
            user.Email.toLowerCase() === email.toLowerCase()
        );

        if (user) {

            if (meeting.Password === password) {


                console.log(user); // Log the found user
                setname(user.Name)
                setentermeeting(true); // Uncomment this line to allow entering the meeting
            } else {

                setcontent("Please Enter Correct Event Password")
                setOpen(true)
            }
        } else {
            // message.error("You have not registered yet. Please complete your registration to join the event");
            setcontent("You have not registered yet. Please complete your registration to join the event")
            setOpen(true)
        }





    }




    const Alertt = ({ Content }) => {


        return (
            <>

                <div className='alerttt'>
                    <div className='alertbg' onClick={() => setOpen(!open)}></div>
                    <div className='alertcontant'>
                        {Content}

                        <button onClick={() => setOpen(!open)} className='registerformdivbutton' style={{ padding: '10px 20px', width: "fit-content" }}>OK</button>
                    </div>
                </div>

            </>
        );
    };



    const sendquestiontobackend = async (e) => {
        e.preventDefault()
        const hide = message.loading("Action in progress", 0)


        const timeanddate = new Date()

        const Question = {
            time: timeanddate,
            name: name,
            email: email,
            quetion: inputquestin

        }




        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("quetion", JSON.stringify(Question));
        urlencoded.append("_id", meeting._id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addquetioninevent", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                setmessagesss(result.questions)
                setinputquestin("")


                setTimeout(() => {
                    hide(); // Call hide to stop the loading message

                }, 2000);


                setregisterform(false)


            })
            .catch((error) => console.error(error));


    }

    const handleSearch = (searchText) => {
        if (!searchText) {
            setOptions([]); // Clear options if no search text
            return;
        }

        // Filter countries that include the search text
        const filteredCountries = countries
            .filter(item => item.name.common.toLowerCase().includes(searchText.toLowerCase()))
            .map(item => ({
                value: item.name.common, // Use country name as value
                label: item.name.common  // Use country name as label
            }));

        setOptions(filteredCountries);
    };


    return (
        <div>
            {/* <Navbar /> */}
            {open && <>
                <Alertt Content={content} />
            </>}
            {entermeeting ? (<>



                {meeting.status ? (<>

                    <div className='aftermeeting' >

                        <div className='sCsSbKP9yxvw4LQAeaTz zego_model_parent screen'>


                            <div className='vadio'>

                                <ReactPlayer className="videoplayer" url={meeting.recording} controls width="100%" height={"100%"} />


                            </div>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div className="vRymZYPLKPwd4n9uqA9E" onClick={() => setentermeeting(false)}></div>
                                <div className="aUBcrib1jsrHTK9vhlVZ " onClick={() => setchatbox(!chatbox)} style={{ width: '50px', height: "50px" }}></div>

                            </div>



                        </div>


                        {chatbox ? (<>
                            <div className="M4HRY2n7rpNAd1UjDNZe quetionnnwala quetionnnwala2" style={{ margin: "20px 35px 20px 0", overflow: 'auto', maxHeight: '90vh', minWidth: '320px' }}>
                                <div className="n6GuqIk3cK3cSgL5gW1Z">Any questions you want to ask?
                                    <span className="UYkwihM45bjIVQZSfpze" onClick={() => setchatbox(!chatbox)}></span>
                                </div><div className="Z2PYS5vNVghY4m46zQQV">
                                    <div className="ycoskpkASJJEiimQJINL">
                                        <div className="z_Raef7MoQTTp7fHENMF">

                                            {messagesss.map((value) => (<>

                                                {value.email === email && <>
                                                    <div class="yZqd3D1to9HjAdrmztDe">
                                                        <div class="g6Dz8R9i4FALDRdhWQ7x">
                                                            <span class="EwMm20qxkaVBq7882Tmx">{value.name}</span>
                                                            <span class="Xy7oIKiLKSjzCBwqt4OA">{value.time}</span>
                                                        </div>
                                                        <p class="false false">{value.quetion}</p>
                                                    </div>
                                                </>}

                                            </>))}

                                        </div>
                                        <form onSubmit={sendquestiontobackend} className="xM8CBkrn0wtFOdOP84Bb">
                                            <textarea value={inputquestin} onChange={(e) => setinputquestin(e.target.value)} required placeholder="Submit your question" />
                                            <button disabled="">
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>) : (<></>)}
                    </div>


                </>) : (<>


                    <div
                        className="myCallContainer"
                        ref={myMeeting}
                        style={{ width: "100%", height: "100vh" }}
                    ></div>
                </>)}
            </>) : (<>
                <div className='maindiv' style={{ flexDirection: 'column' }}>

                    <div className='preview' >
                        <div style={{ width: '100%' }}>  <Image src={meeting.coverphoto} alt='' style={{ width: '100%' }} /></div>
                        <div className='preview22'  >
                            <div className='meetingdetails'>


                                <div className='head'>
                                    <div class="date-card">
                                        <div class="month">{date.month}</div>
                                        <div class="day">{date.day}</div>
                                        <div class="time">{date.hours}:{date.minutes} {date.period}</div>
                                    </div>
                                    <div>
                                        <h2>{meeting.title}</h2>
                                        <p style={{ color: 'gray' }}>By YouGutMap</p>
                                    </div>

                                </div>
                                <div>
                                    <p style={{ marginLeft: '30px' }}>

                                        <span dangerouslySetInnerHTML={{ __html: meeting.About }} />
                                    </p>
                                </div>
                            </div>

                            <div className='meetingjoin'>

                                <CountdownTimer />

                                <div>
                                    {registerform ? (<>

                                        <form className='registerform' onSubmit={handleSubmit}>

                                            {meeting.Fields.map((field, index) => {
                                                return (
                                                    <div key={index}>
                                                        {field.type === "select" ? (
                                                            <>
                                                                <label>{field.title}</label>
                                                                <select
                                                                    value={formData[field.title] || ''}
                                                                    onChange={(e) => handleChange(field.title, e.target.value)}
                                                                    required={field.require}
                                                                >
                                                                    <option disabled value="">Please Select a Value</option>
                                                                    {field.option.map((option, idx) => (
                                                                        <option key={idx} value={option.value}>{option.value}</option>
                                                                    ))}
                                                                </select>
                                                            </>
                                                        ) : field.type === "checkbox" ? (
                                                            <>
                                                                <div style={{ flexDirection: "row", alignItems: 'center', gap: '10px' }}>
                                                                    <input
                                                                        style={{ width: '30px', height: "30px" }}
                                                                        type="checkbox"
                                                                        checked={formData[field.title] || false}
                                                                        onChange={(e) => handleChange(field.title, e.target.checked)}
                                                                        required={field.require}
                                                                    />
                                                                    <label>{field.title}</label>
                                                                </div> </>
                                                        ) : field.type === "Country" ? (
                                                            <>
                                                                <label>{field.title}</label>

                                                                <AutoComplete
                                                                    options={options}
                                                                    style={{ backgroundColor: '#D2CDFF' }}
                                                                    placeholder="Search for Conutry"
                                                                    required
                                                                    className='autocompleee'
                                                                    value={formData[field.title] || ''}
                                                                    onSearch={handleSearch}  // When typing, call handleSearch
                                                                    onSelect={(e) => handleChange(field.title, e)}  // Handle selection
                                                                    onChange={(e) => handleChange(field.title, e)}
                                                                />

                                                                {/* <input
                                                                    type={field.type}
                                                                    value={formData[field.title] || ''}
                                                                    onChange={(e) => handleChange(field.title, e.target.value)}
                                                                    required={field.require}
                                                                /> */}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label>{field.title}</label>
                                                                <input
                                                                    type={field.type}
                                                                    value={formData[field.title] || ''}
                                                                    onChange={(e) => handleChange(field.title, e.target.value)}
                                                                    required={field.require}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                );
                                            })}




                                            <div>

                                                <button >Register</button>
                                            </div>

                                            <div>

                                                Registered Already? <a href='' onClick={(e) => {
                                                    setregisterform(false)
                                                    e.preventDefault()
                                                }}>Join Now</a>
                                            </div>
                                        </form>

                                    </>) : (<>
                                        <form className='registerform' onSubmit={checkuser}>


                                            <div>
                                                <label>Email</label>
                                                <input type='email' required onChange={(e) => setemail(e.target.value)} value={email} />
                                            </div>

                                            <div>
                                                <label>Event Password</label>
                                                <input type='password' required onChange={(e) => setpassword(e.target.value)} value={password} />
                                            </div>

                                            <div>

                                                <button>Join Event</button>
                                            </div>

                                            <div>


                                                <div>

                                                    Haven't Registered Yet? <a href='' onClick={(e) => {
                                                        setregisterform(true)
                                                        e.preventDefault()
                                                    }}>Register Now</a>
                                                </div>
                                            </div>
                                        </form>

                                    </>)}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>)}

        </div>
    )
}

export default Meeting
